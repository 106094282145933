/* eslint-disable no-debugger */
<template>
  <section class="xa-container">
    <div class="msg-loading" v-if="isLoading">
      <Loading type="spinner">加载中...</Loading>
    </div>
    <van-swipe
      v-if="banners && banners.length"
      class="msg-swiper"
      :autoplay="3000"
    >
      <van-swipe-item v-for="(image, index) in banners" :key="index">
        <img :src="image.img" :key="index" />
      </van-swipe-item>
    </van-swipe>
    <div class="msg-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="msg-list__item xa-cell xa-bg-white"
        @click="onItemClick(item)"
      >
        <div class="msg-list__label">
          <img
            v-if="item.img"
            class="msg-list__icon"
            mode="aspectFill"
            :src="item.img"
          />
          <div
            v-if="item.icon"
            class="msg-list__icon xa-cell"
            :style="{ background: item.iconBg }"
          >
            <div class="iconfont xa-txt-17" :class="item.icon"></div>
          </div>
          <div class="msg-list__notice single" v-if="item.notice == 1"></div>
          <div class="msg-list__notice number" v-if="item.notice > 1">
            {{ item.notice }}
          </div>
        </div>
        <div class="msg-list__content xa-flex">
          <div class="xa-txt-primary xa-cell">
            <div class="xa-flex">{{ item.title }}</div>
            <div class="xa-txt-11 xa-txt-secondary">{{ item.time }}</div>
          </div>
          <div class="msg-list__tip xa-txt-14 xa-txt-regular">
            {{ item.subtitle }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ReadableTime } from '@/utils/readableTime'
import { Loading, Swipe, SwipeItem } from 'vant'
import basePage from '@/mixins/basePage'
export default {
  name: 'Cache-message',
  config: {
    title: '消息',
    fetchDataFn: ''
  },
  mixins: [basePage],
  components: {
    Loading,
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem
  },
  data() {
    return {
      isLoading: true,
      banners: [],
      list: []
    }
  },
  methods: {
    onItemClick(item) {
      window.console.log('1', item)
      this.$router.push({
        name: 'message-detail',
        params: { id: this.list.indexOf(item) }
      })
    },
    initListIcon(item) {
      var icons = item.icon.split(/\s/)
      if (icons.length === 2) {
        item.icon = icons[0]
        item.iconBg = icons[1]
      }
      return item
    },
    initList(list) {
      var readableTime = new ReadableTime()
      return list.map(item => {
        if (item.icon) {
          item = this.initListIcon(item)
        }
        item.time = readableTime.getReadableTime(new Date(item.time * 1000))
        return item
      })
    },
    async initView() {
      const { banners } = await this.$_request({
        url: '/mock/banner'
      })
      const { list } = await this.$_request({
        url: '/mock/list'
      })
      this.banners = banners
      this.list = this.initList(list)
    }
  },
  async mounted() {
    await this.initView()
    this.$options.config.fetchDataFn = 'initView'
    this.isLoading = false
  }
}
</script>
<style lang="scss">
.msg-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100vh;
}
.msg-swiper {
  width: 100vw;
  height: 48vw;
  img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
}
.msg-swiper__text {
  position: absolute;
  max-width: 50vw;
  left: 32rpx;
  bottom: 32rpx;
  color: #fff;
  font-size: 30rpx;
}
.msg-swiper + .msg-list {
  margin-top: 8px;
}
.msg-list {
  font-size: 15px;
  .msg-list__icon {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    flex-shrink: 0;
    justify-content: center;
    color: #fff;
    font-size: 18px;
  }
  .msg-list__item {
    position: relative;
    padding: 16px 20px;
    &::after {
      content: ' ';
      position: absolute;
      left: 80px;
      bottom: 0rpx;
      right: 0rpx;
      border-top: 1rpx solid #cccccc;
      transform: scaleY(0.5);
      z-index: 99;
    }
  }
  .msg-list__content {
    height: 48px;
    display: flex;
    padding-left: 12px;
    overflow: hidden;
    line-height: 1.5;
    flex-direction: column;
    justify-content: space-between;
  }
  .msg-list__tip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .msg-list__label {
    position: relative;
  }
  .msg-list__notice {
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
    height: 8px;
    background-color: #f5222d;
    border-radius: 50%;
    &.single {
      right: -4px;
      top: -4px;
    }
    &.number {
      right: -4px;
      top: -4px;
      width: 16px;
      height: 16px;
      font-size: 11px;
      color: #fff;
      text-align: center;
      line-height: 16px;
    }
  }
}
</style>
