<template>
  <section class="xa-container xa-txt-14">
    <div class="msg-detail">
      <div v-for="(item, index) in list" :key="index" class="msg-detail__item">
        <div class="xa-cell">
          <div class="msg-detail__icon">
            <img :src="info.img" />
          </div>
          <div class="msg-detail__content">
            <div v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
export default {
  mixins: [basePage],
  config: {
    title: '消息',
    fetchDataFn: 'intView'
  },
  data() {
    return {
      info: {},
      list: []
    }
  },
  methods: {
    async intView() {
      const { info, list } = await this.$_request({
        url: '/mock/detail',
        data: this.$route.params
      })
      this.info = info
      this.list = list
      this.$nextTick(() => {
        window.scroll({ top: 99999, left: 0, behavior: 'smooth' })
      })
      return {
        title: (info && info.title) || '消息通知'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.xa-container {
  padding-bottom: 40px;
}
.msg-detail {
  &__item {
    padding: 8px;
  }
  &__icon {
    align-self: flex-start;
    line-height: 0;
  }
  &__icon img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
  &__content {
    position: relative;
    padding: 11px 8px;
    margin: 0 8px;
    border-radius: 4px;
    background-color: #fff;
    line-height: 18px;
    &:after {
      content: '';
      position: absolute;
      left: -10px;
      top: 15px;
      width: 0px;
      height: 0px;
      border: 5px solid transparent;
      border-right: 5px solid #fff;
    }
  }
}
</style>
