export function getLocaleDateString(date) {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

export function hour2Milliseconds(hour) {
  return hour * 60 * 60 * 1000
}

/**
 * @param {*} num
 *  3 => 03
 *  11 => 11
 */
export function completionNumToTen(num) {
  return num < 10 ? '0' + num : num
}

/**
 * 获取已有的10分钟时间
 * @param {*} date
 * 11:54 => 11:50
 * 11:59 => 11:50
 */
export function getLastTimeInTen(date) {
  let minutes = parseInt(date.getMinutes() / 10) * 10
  return completionNumToTen(date.getHours()) + ':' + completionNumToTen(minutes)
}
/**
 * 根据当前时间，把传递过来的时间，转化为更加友好可读的日期
 * 今天的 => 10:20
 * 昨天的 => 昨天 10:30
 * 其他 => 2019/01/02
 */
export class ReadableTime {
  constructor() {
    this.curDateTime = new Date()
    this.zeroPointToday = this._getZeroPointToday()
    this.zeroPointYesterday = this._getZeroPointYesterday()
  }
  _getZeroPointToday() {
    return new Date(
      `${this.curDateTime.getFullYear()}/${this.curDateTime.getMonth() +
        1}/${this.curDateTime.getDate()}  00:00:00`
    )
  }
  _getZeroPointYesterday() {
    var zeroPointToday = this._getZeroPointToday()
    zeroPointToday.setDate(zeroPointToday.getDate() - 1)
    return zeroPointToday
  }
  toJson() {
    return {
      now: this.curDateTime.toLocaleString(),
      zeroPointToday: this.zeroPointToday.toLocaleString(),
      zeroPointYesterday: this.zeroPointYesterday.toLocaleString()
    }
  }
  _isInToday(date) {
    return this.zeroPointToday - date < 0
  }
  _isInYesterday(date) {
    return this.zeroPointYesterday - date < 0
  }
  /**
   *
   * @param {*} date Date
   */
  getReadableTime(date) {
    if (this._isInToday(date)) {
      return `${date.getHours()}:${date.getMinutes()}`
    } else if (this._isInYesterday(date)) {
      return `昨天 ${date.getHours()}:${date.getMinutes()}`
    } else {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    }
  }
}
